
















import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import Container from '@/components/Container.vue';
import { RootState } from '@/store/store';
import { ToastProgrammatic as Toast } from 'buefy';
import { BNoticeComponent } from 'buefy/types/components';

@Component({
  components: {
    Container
  },
  beforeRouteLeave(to, from, next) {
    const self = this as any;
    self.indefiniteToast?.close();
    localStorage.removeItem('forbiddenResourceErrorMessage');
    next();
  }
})
export default class AccessForbidden extends Vue {
  @State((state: RootState) => state.auth.isLoggedIn)
  public isLoggedIn!: boolean;
  private indefiniteToast!: BNoticeComponent;

  private goHome() {
    this.indefiniteToast?.close();
    this.$router.push('/');
  }

  private mounted() {
    if (!this.isLoggedIn) {
      window.location.href = `${process.env.VUE_APP_BACKEND_HOSTNAME}/logout`;
    }

    const forbiddenResourceErrorMessage = localStorage.getItem(
      'forbiddenResourceErrorMessage'
    );
    if (forbiddenResourceErrorMessage) {
      this.indefiniteToast = Toast.open({
        message: forbiddenResourceErrorMessage,
        indefinite: true,
        type: 'is-danger'
      });
    }
  }
}
